<template>
  <div class="container patient-follow">
    <patient-head :loading="loading" :info="info" small></patient-head>
    <el-row :gutter="10">
      <el-col :md="24" :lg="9" :xl="6">
        <div class="card" v-loading="loading.form">
          <template v-if="!loading.error">
            <div class="card-head">随访患者情况</div>
            <el-form label-width="80px">
              <el-form-item label="生存状态" class="mb-5">
                <el-radio-group v-model="form.survival">
                  <el-radio label="器官带功" value="1"></el-radio>
                  <el-radio label="器官失功" value="2"></el-radio>
                  <el-radio label="器官移除" value="3"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="并发症" class="mb-5">
                <el-radio-group v-model="form.cShow">
                  <el-radio label="无"></el-radio>
                  <el-radio label="有"></el-radio>
                </el-radio-group>
                <el-input
                  v-if="form.cShow === '有'"
                  v-model="form.complication"
                  resize="none"
                  :rows="3"
                  type="textarea"
                  placeholder="并发症情况描述"
                ></el-input>
              </el-form-item>
              <el-form-item label="排异反应" class="mb-5">
                <el-radio-group v-model="form.eShow">
                  <el-radio label="无"></el-radio>
                  <el-radio label="有"></el-radio>
                </el-radio-group>
                <el-input
                  v-if="form.eShow === '有'"
                  v-model="form.exclusion"
                  resize="none"
                  :rows="3"
                  type="textarea"
                  placeholder="排异反应情况描述"
                ></el-input>
              </el-form-item>
              <el-form-item label="感染" class="mb-5">
                <el-radio-group v-model="form.iShow">
                  <el-radio label="无"></el-radio>
                  <el-radio label="有"></el-radio>
                </el-radio-group>
                <el-input
                  v-if="form.iShow === '有'"
                  v-model="form.infected"
                  resize="none"
                  :rows="3"
                  type="textarea"
                  placeholder="感染情况描述"
                ></el-input>
              </el-form-item>
              <el-form-item label="药物异常" class="mb-5">
                <el-radio-group v-model="form.dShow">
                  <el-radio label="无"></el-radio>
                  <el-radio label="有"></el-radio>
                </el-radio-group>
                <el-input
                  v-if="form.dShow === '有'"
                  v-model="form.drug"
                  resize="none"
                  :rows="3"
                  type="textarea"
                  placeholder="药物异常反应描述"
                ></el-input>
              </el-form-item>
            </el-form>
            <el-button
              plain
              class="float-right"
              size="small"
              @click="addAdjustment"
              >添加记录</el-button
            >
            <div class="card-head pb-0 mt-10">调药记录</div>
            <div
              class="mt-10"
              v-for="(item, index) in form.adjustment"
              :key="'drug_' + index"
            >
              <el-button
                class="float-right mt-5"
                type="danger"
                size="small"
                @click="removeAdjustment(index)"
                >移除记录</el-button
              >
              <el-date-picker
                v-model="item.date"
                type="date"
                placeholder="调药日期"
                style="width: 140px"
              />
              <el-input
                v-model="item.content"
                class="mt-5"
                resize="none"
                :rows="3"
                type="textarea"
                placeholder="调药内容"
              ></el-input>
            </div>
            <div class="card-head mt-10">随访建议</div>
            <el-input
              v-model="form.proposal"
              resize="none"
              :rows="5"
              type="textarea"
              placeholder="请输入对患者的建议或诊断..."
            ></el-input>
            <div class="text-center" v-if="follow.info">
              <el-button
                type="primary"
                class="mt-10"
                style="width: 120px"
                @click="save('add')"
                v-if="follow.info.state == 1"
                >保存</el-button
              >
              <el-button
                type="primary"
                class="mt-10"
                style="width: 120px"
                @click="save('update')"
                v-else-if="follow.info.state == 2"
                >保存变更</el-button
              >
            </div>
          </template>
          <el-result
            v-else
            icon="error"
            title="加载失败"
            style="padding: 150px 0"
          />
        </div>
      </el-col>
      <el-col :md="24" :lg="15" :xl="18">
        <div class="card" v-loading="loading.case" style="height: 130px">
          <div class="card-head">就诊信息</div>
          <el-descriptions :column="3" v-if="follow.info">
            <el-descriptions-item label="就诊日期" width="200px">{{
              follow.info.date
            }}</el-descriptions-item>
            <el-descriptions-item label="就诊类型" width="200px">
              <span v-if="follow.info.type === 1">计划复查</span>
              <span v-if="follow.info.type === 2">临时复查</span>
            </el-descriptions-item>
            <el-descriptions-item
              label="计划节点"
              v-if="follow.info.type === 1 && follow.plan"
              >{{ follow.plan.label }}</el-descriptions-item
            >
          </el-descriptions>
          <el-descriptions :column="1" v-if="follow.hospital">
            <el-descriptions-item label="就诊医院" width="400px">{{
              follow.hospital.name
            }}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions :column="3" v-if="follow.info">
            <el-descriptions-item label="就诊科室" width="200px">{{
              follow.info.department
            }}</el-descriptions-item>
            <el-descriptions-item label="接诊医生" width="200px">{{
              follow.info.doctor
            }}</el-descriptions-item>
            <el-descriptions-item label="就诊城市"
              >{{ follow.hospital.province }}
              {{ follow.hospital.city }}</el-descriptions-item
            >
          </el-descriptions>
        </div>
        <div
          class="card pb-0"
          v-loading="loading.case"
          style="min-height: 100px"
          v-if="follow.index && follow.info && follow.info.list != '[]'"
        >
          <el-descriptions
            v-for="item in follow.index"
            :key="'g_' + item.id"
            :title="item.name"
            :column="3"
          >
            <el-descriptions-item
              v-for="sub in item.list"
              :key="'t_' + sub.id"
              :label="sub.label"
              width="33%"
            >
              <span v-if="sub.value">
                <span>{{ sub.value }}</span>
                <span class="unit">{{ sub.unit }}</span>
              </span>
              <span v-else style="color: #999">未检测</span>
              <span class="data-line" @click="openFullScreen(sub)">
                <!-- <el-icon><data-line /></el-icon> -->
                <img src="/img/chart.png" />
              </span>
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="card pb-0" v-loading="loading.case" v-if="follow.file">
          <template v-for="(item, key) in follow.file" :key="key">
            <div v-if="item.length > 0">
              <div class="card-head mt-0">{{ type[key] }}</div>
              <div class="pb-10">
                <el-image
                  v-for="(img, i) in item"
                  :src="img"
                  :key="key + '_' + i"
                  :preview-src-list="item"
                  :initial-index="i"
                  fit="cover"
                  class="sub-img"
                >
                  <template #placeholder>
                    <div class="el-loading-spinner">
                      <svg class="circular" viewBox="25 25 50 50">
                        <circle
                          class="path"
                          cx="50"
                          cy="50"
                          r="20"
                          fill="none"
                        ></circle>
                      </svg>
                    </div>
                  </template>
                </el-image>
              </div>
            </div>
          </template>
        </div>
        <div
          class="card mb-0"
          v-loading="loading.case"
          style="min-height: 100px"
          v-if="follow.info"
        >
          <div class="card-head">患者备注</div>
          <div class="remarks">{{ follow.info.remarks }}</div>
        </div>
      </el-col>
    </el-row>
    <el-dialog v-model="fullScreen.show" title="" fullscreen>
      <div id="fullscreen" v-loading="fullScreen.loading"></div>
    </el-dialog>
  </div>
</template>

<script>
import PatientHead from "../../components/patient/patient-head.vue";
import { common, index, patient, follow } from "../../plugin/api";
// import { DataLine } from "@element-plus/icons";
import { date } from "../../plugin/util";
import * as echarts from "echarts";

export default {
  name: "PatientFollow",
  components: { 
    // DataLine, 
    PatientHead },
  data: () => ({
    id: 0,
    loading: {
      info: true,
      case: true,
      form: true,
      error: false,
    },
    info: {},
    form: {
      survival: "",
      cShow: "",
      complication: "",
      eShow: "",
      exclusion: "",
      iShow: "",
      infected: "",
      dShow: "",
      drug: "",
      adjustment: [],
      proposal: "",
    },
    follow: {},
    feedback: {},
    type: {
      outpatient: "门诊病历",
      prescription: "处方单",
      test: "化验单",
      inspect: "检查报告",
    },
    fullScreen: {
      show: false,
      loading: true,
    },
  }),
  methods: {
    init() {
      this.updateLoading(true, true, true, false);
      this.info = {
        id: this.$route.params.id,
        name: "",
      };
      this.queryInfo();
    },
    updateLoading(l1, l2, l3, err) {
      this.loading = {
        info: l1,
        case: l2,
        form: l3,
        error: err,
      };
    },
    queryInfo() {
      patient
        .getInfo(this.info.id, false)
        .then((res) => {
          if (res.state) {
            setTimeout(() => {
              this.info = res.data.info;
              this.loading.info = false;
              this.queryFollow();
            }, 300);
          } else {
            this.updateLoading(false, true, true, true);
          }
        })
        .catch(() => {
          this.updateLoading(false, true, true, true);
        });
    },
    queryFollow() {
      follow
        .getInfo(this.id)
        .then((res) => {
          setTimeout(() => {
            if (res.state) {
              res.data.info.date = date.format(
                res.data.info.date,
                "yyyy-mm-dd"
              );
              res.data.index = this.buildIndex(
                res.data.info.list,
                res.data.plan
              );
              res.data.file = this.buildFiles(res.data.file);
              this.follow = res.data;
              this.loading.case = false;
              if (this.follow.info.state == 2) this.queryFeedback();
              else this.loading.form = false;
            } else {
              this.updateLoading(false, false, true, true);
            }
          }, 500);
        })
        .catch(() => {
          this.updateLoading(false, false, true, true);
        });
    },
    queryFeedback() {
      follow
        .getFeedback(this.id)
        .then((res) => {
          if (res.state) {
            let data = res.data;
            if (data.survival == 1) data.survival = "器官带功";
            else if (data.survival == 2) data.survival = "器官失功";
            else if (data.survival == 3) data.survival = "器官移除";
            this.form = {
              survival: data.survival,
              cShow: data.complication ? "有" : "无",
              complication: data.complication ? data.complication : "",
              eShow: data.exclusion ? "有" : "无",
              exclusion: data.exclusion ? data.exclusion : "",
              iShow: data.infected ? "有" : "无",
              infected: data.infected ? data.infected : "",
              dShow: data.drug ? "有" : "无",
              drug: data.drug ? data.drug : "",
              adjustment: data.adjustment ? JSON.parse(data.adjustment) : [],
              proposal: this.follow.info.proposal
                ? this.follow.info.proposal
                : "",
            };
            setTimeout(() => {
              this.loading.form = false;
            }, 500);
          } else {
            this.updateLoading(false, false, false, true);
          }
        })
        .catch(() => {
          this.updateLoading(false, false, false, true);
        });
    },
    addAdjustment() {
      this.form.adjustment.push({
        date: date.format(new Date().getTime(), "yyyy-mm-dd"),
        content: "",
      });
    },
    removeAdjustment(index) {
      this.$confirm("移除后无法恢复,确认要移除吗?", "移除调药记录", {
        confirmButtonText: "确认移除",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.form.adjustment.splice(index, 1);
      });
    },
    buildIndex(list, plan) {
      let index = JSON.parse(JSON.stringify(plan.index));
      for (let a in index) {
        let group = index[a];
        for (let b in group.list) {
          let sub = group.list[b];
          for (let c in list) {
            if (list[c].tid == sub.id) {
              sub.value = list[c].value;
              sub.tid = list[c].tid;
            }
          }
        }
      }
      return index;
    },
    buildFiles(list) {
      if (list && list.length > 0) {
        let map = {
          outpatient: [],
          prescription: [],
          test: [],
          inspect: [],
        };
        for (let i in list) {
          map[list[i].tag].push(common.getHostUrl() + list[i].url);
        }
        return map;
      } else return undefined;
    },
    showWarning(msg) {
      this.$message.warning({
        message: msg,
        center: true,
      });
      return false;
    },
    save(type) {
      if (this.form.survival == "") return this.showWarning("受体生存情况必选");
      if (this.form.cShow == "") return this.showWarning("并发症情况必选");
      if (this.form.eShow == "") return this.showWarning("排异反应情况必选");
      if (this.form.iShow == "") return this.showWarning("感染情况必选");
      if (this.form.dShow == "") return this.showWarning("药物异常情况必选");
      this.$confirm(
        "保存前请确认各项参数填写是否正确, 确认保存?",
        this.follow.info.state == 1 ? "保存随访反馈" : "保存变更",
        {
          confirmButtonText: "确认保存",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        let survival = 1;
        if (this.form.survival == "器官失功") survival = 2;
        else if (this.form.survival == "器官移除") survival = 3;
        let form = {
          fid: this.id,
          pid: this.info.id,
          survival: survival,
        };
        if (this.form.cShow) form.complication = this.form.complication;
        if (this.form.eShow) form.exclusion = this.form.exclusion;
        if (this.form.iShow) form.infected = this.form.infected;
        if (this.form.dShow) form.drug = this.form.drug;
        if (this.form.adjustment.length > 0) {
          for (let i in this.form.adjustment) {
            if (this.form.adjustment[i].date.length == undefined)
              this.form.adjustment[i].date = date.format(
                this.form.adjustment[i].date.getTime(),
                "yyyy-mm-dd"
              );
            else if (this.form.adjustment[i].date.length > 10)
              this.form.adjustment[i].date = this.form.adjustment[
                i
              ].date.substring(0, 10);
          }
          form.adjustment = JSON.stringify(this.form.adjustment);
        }
        if (this.form.proposal) form.proposal = this.form.proposal;
        this.loading.form = true;
        follow
          .submitFeedback(type, form)
          .then((res) => {
            setTimeout(() => {
              this.loading.form = false;
              if (res.state) {
                this.$message.success({
                  message: "保存成功",
                  center: true,
                });
                this.queryFollow();
              } else this.showWarning("保存失败");
            }, 500);
          })
          .catch(() => {
            this.loading.form = true;
          });
      });
    },
    openFullScreen(sub) {
      this.fullScreen.show = true;
      this.fullScreen.loading = true;
      index
        .getSingleItemList(sub.tid, this.info.id)
        .then((res) => {
          if (res.state) {
            setTimeout(() => {
              this.fullScreen.loading = false;
              echarts.init(document.getElementById("fullscreen")).dispose();
              let chartObj = echarts.init(
                document.getElementById("fullscreen")
              );
              chartObj.setOption({
                title: {
                  text: sub.label,
                  x: "center",
                  textStyle: {
                    fontSize: 14,
                    color: "#333",
                  },
                  top: 5,
                },
                tooltip: {
                  trigger: "axis",
                },
                xAxis: {
                  type: "category",
                  data: res.data.date,
                },
                grid: {
                  top: "50px",
                  left: "35px",
                  right: "15px",
                  bottom: "30px",
                },
                yAxis: {
                  type: "value",
                  scale: true,
                  name: sub.unit ? sub.unit : "",
                },
                series: [
                  {
                    name: sub.label,
                    type: "line",
                    data: res.data.value,
                  },
                ],
              });
            }, 800);
          } else {
            this.showWarning("获取单项指标统计数据失败");
            this.fullScreen.show = false;
          }
        })
        .catch(() => {
          this.showWarning("网络错误");
          this.fullScreen.show = false;
        });
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    this.info.id = this.$route.params.id;
    this.init();
  },
};
</script>

<style>
.follow-tabs .el-tabs__nav-scroll {
  padding: 1.5px 10px;
}

.follow-tabs .el-tabs__header.is-top {
  margin-bottom: 10px;
}

.follow-tabs > .el-tabs__content {
  padding: 0 10px 10px 10px;
}
</style>

<style scoped>
.patient-head {
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  display: flex;
  width: 100%;
}

.patient-head .title {
  align-items: center;
  display: flex;
}

.patient-head .title > span {
  margin-left: 10px;
  font-size: 18px;
}

.card-head {
  font-weight: 700;
}

.btn {
  background-color: #f4f4f4;
  color: #222;
}

.btn:hover {
  background-color: #e2e2e2;
  color: #222;
}

.btn:active,
.btn.active {
  background-color: #d4d3d3;
  color: #222;
}

.adjustment {
  align-items: center;
  margin-bottom: 5px;
  font-size: 14px;
  display: flex;
  color: #333;
}

.remarks {
  font-size: 14px;
  color: #555;
}

.unit {
  margin-left: 5px;
  color: #999;
}

.sub-img {
  margin: 0 10px 10px 0;
  height: 100px;
  width: 100px;
}

.data-line {
  position: absolute;
  margin-left: 10px;
  margin-top: 2px;
  font-size: 18px;
  cursor: pointer;
  color: #999;
}

.data-line:hover {
  color: #333;
}

#fullscreen {
  height: calc(100vh - 90px);
  width: 100%;
}
</style>